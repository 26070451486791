import React, { useState } from "react";
import { alphabet, wordList } from "../constants";
import { useWindowSizes } from "react-use-window-sizes";

const name = ["W", "O", "R", "D", "L", "E"];
const name2 = ["W", "E", "A", "S", "E", "L"];
const title = ["S", "U", "G", "G", "E", "S", "T", "I", "O", "N", "S"];

const greenRows = 1;
const yellowRows = 3;
const greyRows = 3;
const wordLen = 5;

const generateLetterValues = (words) => {
  const values = {};
  alphabet.forEach((l) => {
    values[l] = [...Array(wordLen).keys()].map(() => 0);
  });

  words.forEach((word) => {
    for (var pos = 0; pos < word.length; pos++) {
      values[word[pos]][pos] += 1;
    }
  });

  return values;
};

const scoreWords = (words, letterValues, excludeLetters = [], excludeDuplicateLetters = false) => {
  var maxScore = 0;
  var minScore = 10 ** 10;

  const scores = [];
  words.forEach((w) => {
    let score = 0;

    if (excludeDuplicateLetters) {
      if (!excludeLetters.includes(w[0])) score += letterValues[w[0]][0];
      if (!excludeLetters.includes(w[1]) && !w.slice(0, 1).includes(w[1])) score += letterValues[w[1]][1];
      if (!excludeLetters.includes(w[2]) && !w.slice(0, 2).includes(w[2])) score += letterValues[w[2]][2];
      if (!excludeLetters.includes(w[3]) && !w.slice(0, 3).includes(w[3])) score += letterValues[w[3]][3];
      if (!excludeLetters.includes(w[4]) && !w.slice(0, 4).includes(w[4])) score += letterValues[w[4]][4];
    } else {
      if (!excludeLetters.includes(w[0])) score += letterValues[w[0]][0];
      if (!excludeLetters.includes(w[1])) score += letterValues[w[1]][1];
      if (!excludeLetters.includes(w[2])) score += letterValues[w[2]][2];
      if (!excludeLetters.includes(w[3])) score += letterValues[w[3]][3];
      if (!excludeLetters.includes(w[4])) score += letterValues[w[4]][4];
    }

    // score += letterValues[w[0]][0];
    // if (!w.slice(0, 1).includes(w[1])) score += letterValues[w[1]][1];
    // if (!w.slice(0, 2).includes(w[1])) score += letterValues[w[2]][2];
    // if (!w.slice(0, 3).includes(w[1])) score += letterValues[w[3]][3];
    // if (!w.slice(0, 4).includes(w[1])) score += letterValues[w[4]][4];

    if (score > maxScore) maxScore = score;
    if (score < minScore) minScore = score;
    scores.push({ word: w, score });
  });

  scores.forEach((s) => {
    if (maxScore - minScore === 0) {
      s.score = 1.0;
    } else {
      s.score = (s.score - minScore) / (maxScore - minScore);
    }
  });
  return scores;
};

const rankWords = (word1, word2) => {
  return word1.score < word2.score ? 1 : -1;
};

const App = () => {
  const [greenLetters, setGreenLetters] = useState([...Array(wordLen * greenRows).keys()].map(() => ""));
  const [yellowLetters, setYellowLetters] = useState([...Array(wordLen * yellowRows).keys()].map(() => ""));
  const [greyLetters, setGreyLetters] = useState([...Array(wordLen * greyRows).keys()].map(() => ""));
  const { height } = useWindowSizes();

  const changeGreen = (i, v) => {
    const newLetters = [...greenLetters];
    newLetters[i] = v.toLowerCase();
    setGreenLetters(newLetters);
  };

  const changeYellow = (i, v) => {
    const newLetters = [...yellowLetters];
    newLetters[i] = v.toLowerCase();
    setYellowLetters(newLetters);
  };

  const changeGrey = (i, v) => {
    const newLetters = [...greyLetters];
    newLetters[i] = v.toLowerCase();
    setGreyLetters(newLetters);
  };

  const greenFilter = (word) => {
    return greenLetters.every((l, i) => {
      if (!l) return true;
      if (word[i] === l) {
        return true;
      } else {
        return false;
      }
    });
  };

  const yellowFilter = (word) => {
    return yellowLetters.every((l, i) => {
      if (!l) return true;
      let idx = i % wordLen;
      if (word.includes(l) && word[idx] !== l) {
        return true;
      } else {
        return false;
      }
    });
  };

  const greyFilter = (word) => {
    return greyLetters.every((l) => {
      if (!l) return true;
      if (word.includes(l)) {
        return false;
      } else {
        return true;
      }
    });
  };

  const possibleWords = wordList.filter((w) => greenFilter(w) && yellowFilter(w) && greyFilter(w));

  const letterValues = generateLetterValues(possibleWords);

  const excludeLetters = [...greenLetters, ...greenLetters];

  const scoredWords = scoreWords(possibleWords, letterValues, excludeLetters, true);

  const rankedWords = scoredWords.sort((a, b) => rankWords(a, b));

  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <div className="container" style={{ minHeight: height - 20, width: 64 * wordLen }}>
        <div>
          <div className="fdcc">
            <div style={{ display: "flex" }}>
              {name.map((n, key) => (
                <div key={key} className="name-letter">
                  {n}
                </div>
              ))}
            </div>
            <div style={{ display: "flex" }}>
              {name2.map((n, key) => (
                <div key={key} className="name-letter">
                  {n}
                </div>
              ))}
            </div>
          </div>
          <div style={{ paddingTop: 10 }}>
            {/* Green Letters */}
            {greenLetters.map((l, i) => (
              <input
                key={`${i}-${l}`}
                value={l}
                onChange={(e) => changeGreen(i, e.target.value)}
                className="green-input"
              />
            ))}

            {/* Yellow Letters */}
            {yellowLetters.map((l, i) => (
              <input
                key={`${i}-${l}`}
                value={l}
                onChange={(e) => changeYellow(i, e.target.value)}
                className="yellow-input"
              />
            ))}

            {/* Grey Letters */}
            {greyLetters.map((l, i) => (
              <input
                key={`${i}-${l}`}
                value={l}
                onChange={(e) => changeGrey(i, e.target.value)}
                className="grey-input"
              />
            ))}

            <div className="suggTitle">
              {title.map((n, key) => (
                <div key={key} className="name-letter">
                  {n}
                </div>
              ))}
            </div>
            <div>
              {rankedWords.map((w) => (
                <WordResult key={w.word} word={w.word} score={w.score} />
              ))}
            </div>
          </div>
        </div>
        <div className="fdcc" style={{ marginTop: 20 }}>
          <div style={{ display: "flex", alignItems: "center", fontSize: 11, color: "rgba(0,0,0,.7)" }}>
            Made in <img alt="" src="/laOutline.png" style={{ marginLeft: 5, marginRight: 5, width: 20 }} />{" "}
            with ❤️
          </div>
          <div style={{ fontSize: 11, color: "rgba(0,0,0,.7)", marginBottom: 10 }}>
            &copy; RoboFox LLC, 2022
          </div>
        </div>
      </div>
    </div>
  );
};

const WordResult = ({ word, score }) => {
  const barWidth = 200 * score;
  var barBackground = "rgb(255, 200, 0)";
  if (score > 0.8) {
    barBackground = "rgb(0, 170, 0)";
  } else if (score < 0.5) {
    barBackground = "rgb(60, 60, 60)";
  }

  const formattedScore = (100 * score).toFixed(1);

  return (
    <div style={{ display: "flex", alignItems: "center", fontSize: 16, margin: 7 }}>
      <p style={{ fontSize: 16, width: 45 }}>{word}</p>
      <div style={{ background: barBackground, width: barWidth, height: 15, margin: "0px 10px" }} />
      {`${formattedScore >= 100.0 ? 99.9 : formattedScore}`}
    </div>
  );
};

export default App;
